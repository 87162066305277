<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-img class="mr-5" src="/logo.svg" max-height="50" max-width="50"></v-img>
      <div class="d-flex align-center">
        <h1>RustyNode.org</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://git.rustynode.org/RustyNode"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col cols="9">
            <v-card>
              <v-card-title>What is rustynode?</v-card-title>
              <v-card-text>
                Rustynode is an open source full node implimentation of LDK
              </v-card-text>
            </v-card>
            <br>
            <v-card>
              <v-card-title>Who is it for?</v-card-title>
              <v-card-text>
                Rustynode is intended to be used by merchants that wish to accept bitcoin payments.
              </v-card-text>
            </v-card>
            <br>
            <v-card>
              <v-card-title>Whats is different about it?</v-card-title>
              <v-card-text>
                Rustynode is an automatic lightning node, once installed it will open channels and create incoming liquidity ready to accept payments.
              </v-card-text>
            </v-card>
            <br>
            <v-row>
              <v-col>
                 <v-card>
                  <v-card-title>How does it work?</v-card-title>
                  <v-card-text>
                    Rustynode uses a collection of docker containers
                    <v-list>
                      <v-list-item href="https://bitcoincore.org/bin/bitcoin-core-22.0" target="blank">
                        - bitcoin core
                      </v-list-item>
                      <v-list-item href="https://hub.docker.com/r/iangregsondev/electrs" target="blank">
                        - electrs
                      </v-list-item>
                      <v-list-item href="https://git.rustynode.org/RustyNode/Ln" target="blank">
                        - lightning
                      </v-list-item>
                      <v-list-item href="https://git.rustynode.org/RustyNode/Dashboard" target="blank">
                        - dashboard
                      </v-list-item>
                      <v-list-item href="https://github.com/mempool/mempool" target="blank">
                        - mempool.space
                      </v-list-item>
                      <v-list-item href="https://github.com/cryptoadvance/docker-specter-desktop" target="blank">
                        - specter-desktop
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-title>Donate</v-card-title>
                  <v-card-actions>
                    <v-tabs color="grey">
                      <v-tab @click="setTestnet">Testnet</v-tab>
                      <v-tab @click="setMainnet">Mainnet</v-tab>
                      <v-tab @click="setLn">LNURL</v-tab>
                    </v-tabs>
                  </v-card-actions>
                    <v-sheet
                    class="pa-11"
                    color="white"
                    elevation="1"
                    height="377"
                    width="377"
                    >
                      <qrcode-vue class="mx-auto" size="300" :value="address" />
                    
                      
                    </v-sheet>
                      
                    
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
             <v-card>
              <v-card-title>Current Progress</v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-icon>mdi-check-circle-outline</v-icon><v-spacer></v-spacer>Manual channel open/close<v-spacer></v-spacer> 
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-check-circle-outline</v-icon><v-spacer></v-spacer>Manual add peer<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-check-circle-outline</v-icon><v-spacer></v-spacer>List channels<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-check-circle-outline</v-icon><v-spacer></v-spacer>List peers<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Multisig support<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Automatic channel open<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Automatic drain<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Manager container<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Redis data store<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>Tor access<v-spacer></v-spacer>
                  </v-list-item>
                  <v-list-item>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon><v-spacer></v-spacer>LNURL<v-spacer></v-spacer>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <br>
             <v-card>
                  <v-card-title>How do I install it?</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        git clone 
                      </v-list-item>
                      <v-list-item>
                        cd
                      </v-list-item>
                      <v-list-item>
                        docker-compose up
                      </v-list-item>
                    </v-list>                
                  </v-card-text>
                </v-card>
             
          </v-col>
        </v-row>
      </v-container>
     
    </v-main>
  </v-app>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

 
export default {
  name: 'App',
  components: {
    QrcodeVue
  },

  data: () => ({
    address: "tb1q5xc6lv8sazcl563r2n3e745sjexr87u444lgzun33fxgvnkmlfyssms9uk"
  }),
  methods: {
    setTestnet() {
      this.address = "tb1q5xc6lv8sazcl563r2n3e745sjexr87u444lgzun33fxgvnkmlfyssms9uk"
    },
    setMainnet() {
      this.address = "bc1q40w73c64vruhed84qsneqelf0qhahx2lnfllptg6hjvy055mlnzsuprtus"
    },
    setLn() {
      this.address = "LNURL1DP68GURN8GHJ7MRWVF5HGUEWD3HXGETK9E3K7TMVDE6HYMRS9ASHQ6F0WCCJ7MRWW4EXCTEJ2FV0P2"
    }
  }
};
</script>
